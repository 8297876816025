<template>
  <div class="test vh-100">
    <chart
      ref="chart"
      @range="rangeChangeHandler"
    >
      <div>
        <label>Diapason timeframe</label>
        <timeframes
          v-model="diapasonTimeframe"
        />

        <label>Priority timeframe</label>
        <timeframes
          v-model="zigzagPriorityTimeframe"
        />

        <label>Zigzag timeframe</label>
        <timeframes
          v-model="zigzagTimeframe"
        />
      </div>
    </chart>
  </div>
</template>

<script>

import Vue from 'vue'
import Chart from '@/components/Chart'
import Timeframes from '@/components/Timeframes'

export default {
  components: {
    Chart,
    Timeframes,
  },

  data() {
    return {
      diapasonTimeframe: 30,
      zigzagPriorityTimeframe: 240,
      zigzagTimeframe: 30,
    }
  },

  watch: {
    diapasonTimeframe() {
      this.rangeChangeHandler(this.$refs.chart.getRange())
    },
    zigzagPriorityTimeframe() {
      this.rangeChangeHandler(this.$refs.chart.getRange())
    },
    zigzagTimeframe() {
      this.rangeChangeHandler(this.$refs.chart.getRange())
    },
  },

  methods: {
    async rangeChangeHandler(range) {
      const data = await this.loadData(range)

      this.$refs.chart.clearFigures()
      this.$refs.chart.drawFigures(data)
    },

    async loadData([startDate, endDate], merge = true) {
      const figures = await this.$api.getResearchDiapasons({
        ...this.$refs.chart.brokerData,
        timeframe: 1,
        zigzagPriorityTimeframe: this.zigzagPriorityTimeframe,
        diapasonTimeframe: this.diapasonTimeframe,
        startDate: startDate,
        endDate: endDate,
      })

      const zigzag = await this.$api.getZigzag({
        ...this.$refs.chart.brokerData,
        timeframe: this.zigzagTimeframe,
        startDate: startDate,
        endDate: endDate,
      })

      if (!zigzag.line) {
        zigzag.line = []
      }

      console.log('zigzag', zigzag)

      return {
        ...figures,
        line: figures.line.concat(zigzag.line),
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
