<template>
  <div
    ref="chartContainer"
    class="h-100"
  >
    <div class="controls-header">
      <timeframes
        v-model="timeframe"
      />

      <slot />
    </div>

    <trading-vue
      ref="tradingVue"
      :data="dc"
      :overlays="overlays"
      :width="chartWidth"
      :height="chartHeight"
      :toolbar="false"
      color-grid="rgba(0,0,0,0)"
      @range-changed="rangeChangeHandler"
      @grid-mousedown="onmousedown"
    />
  </div>
</template>

<script>
import { TradingVue, DataCube } from 'trading-vue-js'
import ohlcvMapper from '@aitt/shared/ohlcv-mapper'
import * as time from '@aitt/shared/time'
import { MS_PER_SECOND, MINUTE } from '@aitt/shared/time'
import Mousetrap from 'mousetrap'

import LinesOverlay from './Overlays/LinesOverlay'
import RectsOverlay from './Overlays/RectsOverlay'
import TextsOverlay from './Overlays/TextsOverlay'
import Timeframes from './Timeframes'

export default {
  components: {
    TradingVue,
    Timeframes,
  },

  props: {

  },

  data() {
    return {
      timeframe: 1,
      ticker: 'EURUSD',
      brokerId: 219,

      chartWidth: 800,
      chartHeight: 400,

      dc: new DataCube({
        chart: {},
        onchart: [],
        offchart: [],
      }),

      overlays: [
        LinesOverlay,
        RectsOverlay,
        TextsOverlay,
      ],

      drawingMode: true,
    }
  },

  computed: {
    brokerData() {
      return {
        brokerId: this.brokerId,
        ticker: this.ticker,
        timeframe: this.timeframe,
      }
    },
  },

  watch: {
    timeframe() {
      this.loadCandles(this.$refs.tradingVue.getRange())
    },
  },

  async mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()

    const brokers = await this.$api.getBrokers()

    const selectedBroker = brokers[0]

    console.log(selectedBroker)

    this.brokerId = selectedBroker.brokerId
    this.ticker = selectedBroker.ticker

    const startDate = selectedBroker.startDate
    const endDate = selectedBroker.startDate + 60 * 1000 * 500

    await this.loadCandles([startDate, endDate], false)

    this.$nextTick(() => {
      this.$refs.tradingVue.setRange(startDate, endDate)
    })

    this.bindKeys()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    this.unbindKeys()
  },

  methods: {
    /**
     * Clear figures
     */
    clearFigures() {
      this.dc.del('DataLines')
      this.dc.del('RectsOverlay')
      this.dc.del('TextsOverlay')
    },

    drawFigures(figures) {
      /**
       * Draw lines
       */
      if (figures.line) {
        this.dc.add('onchart', {
          type: 'LinesOverlay',
          name: 'DataLines',
          data: [],
          settings: {
            lines: figures.line,
            'z-index': 2,
          },
        })
      }

      /**
       * Draw rects
       */
      if (figures.rect) {
        this.dc.add('onchart', {
          type: 'RectsOverlay',
          data: [],
          settings: {
            rects: figures.rect,
            'z-index': 2,
          },
        })
      }

      /**
       * Draw texts
       */
      if (figures.text) {
        this.dc.add('onchart', {
          type: 'TextsOverlay',
          data: [],
          settings: {
            texts: figures.text,
            'z-index': 2,
          },
        })
      }
    },

    getRange() {
      return this.$refs.tradingVue.getRange()
    },

    resizeHandler() {
      const { width, height } = this.$refs.chartContainer.getBoundingClientRect()

      this.chartWidth = width
      this.chartHeight = height
    },

    setBrokerData({ ticker, brokerId, startDate, endDate }) {
      this.ticker = ticker
      this.brokerId = brokerId

      this.clearFigures()
      this.loadCandles([startDate, endDate])

      this.$nextTick(() => {
        this.$refs.tradingVue.setRange(startDate, endDate)
      })
    },

    async loadCandles([startDate, endDate], merge = true) {
      const overlap = 100 * this.timeframe * time.MINUTE

      const candles = await this.$api.getCandles({
        ...this.brokerData,
        startDate: startDate - overlap,
        endDate: endDate + overlap,
      })

      const flatCandles = candles.map(ohlcvMapper)

      if (merge) {
        this.dc.set('chart', {
          type: 'Candles',
          data: flatCandles,
          settings: {
            priceLine: false,
          },
        })
      } else {
        this.dc.merge('chart.data', flatCandles)
      }
    },

    rangeChangeHandler() {
      clearTimeout(this.rangeTimer)

      this.rangeTimer = setTimeout(async () => {
        const range = this.$refs.tradingVue.getRange()

        await this.loadCandles(range)

        this.$emit('range', range)
      }, 100)
    },

    goto(candleCount) {
      const right = this.$refs.tradingVue.getRange()[1]

      this.$refs.tradingVue.goto(right + MINUTE * this.timeframe * candleCount)
    },

    addTechLines(lines) {
      const techLines = this.dc.get('TechLines')

      if (techLines.length === 0) {
        this.dc.add('onchart', {
          type: 'LinesOverlay',
          name: 'TechLines',
          data: [],
          settings: {
            lines,
            'z-index': 3,
          },
        })
      } else {
        this.dc.get('TechLines.settings')[0].lines.push(...lines)
      }
    },

    bindKeys() {
      Mousetrap.bind('right', () => {
        this.goto(1)
      })

      Mousetrap.bind('left', () => {
        this.goto(-1)
      })

      Mousetrap.bind('shift+right', () => {
        this.goto(10)
      })

      Mousetrap.bind('shift+left', () => {
        this.goto(-10)
      })

      Mousetrap.bind('alt+h', () => {
        const cursor = this.$refs.tradingVue.getCursor()

        this.addTechLines([
          {
            type: 'line',
            points: [
              [0, cursor.y$],
              [99999999999999, cursor.y$],
            ],
            color: 'rgba(0, 0, 255, 0.7)',
            width: 2,
            dashed: null,
          },
        ])
      })

      Mousetrap.bind('alt+v', () => {
        const cursor = this.$refs.tradingVue.getCursor()

        this.addTechLines([
          {
            type: 'line',
            points: [
              [cursor.t, 0],
              [cursor.t, 2],
            ],
            color: 'rgba(0, 0, 255, 0.7)',
            width: 2,
            dashed: null,
          },
        ])
      })

      Mousetrap.bind('esc', () => {
        this.dc.del('TechLines')
      })
    },

    unbindKeys() {
      Mousetrap.unbind('right, left, shift+right, shift+left')
    },

    onmousedown(event) {
      // console.log(arguments)
    },
  },
}
</script>

<style lang="stylus">
  .trading-vue-legend
    .t-vue-ind,
    .t-vue-title
      display none

  .controls-header
    position fixed
    top 5px
    right 72px
    z-index 1
    color #eee

    label
      font-size 12px
      color #aaa
      padding-top 12px

</style>
