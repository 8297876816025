<template>
  <div class="timeframes">
    <div
      v-for="tf in timeframes"
      :key="tf.data"
      class="timeframes__item"
      :class="{'timeframes__item--selected': tf.data === value}"
      @click="$emit('input', tf.data)"
    >
      {{ tf.name }}
    </div>
  </div>
</template>

<script>
/* eslint-disable strict-vue/require-jsdoc */
const DEFAULT_TIMEFRAMES = [1, 5, 15, 30, 60, 60 * 4, 60 * 24]

const TIMEFRAME_NAME = {
  1: '1m',
  5: '5m',
  10: '10m',
  15: '15m',
  30: '30m',
  60: '1h',
  240: '4h',
  1440: 'D',
}

export default {
  components: {
  },

  props: {
    value: {
      type: Number,
      default: 1,
    },

    list: {
      type: Array,
      default: function () {
        return DEFAULT_TIMEFRAMES
      },
    },
  },

  computed: {
    timeframe: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    timeframes() {
      return this.list.map(l => {
        return {
          name: TIMEFRAME_NAME[l],
          data: l,
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .timeframes
    display flex
    align-items center

    &__item
      margin-right 2px
      padding 0 4px
      height 24px
      min-width 24px
      text-align center
      line-height 24px
      cursor pointer
      border-radius: 3px

      &:hover:not(.timeframes__item--selected)
        background rgba(30, 144, 255, 0.25)

      &--selected
        color #1E90FF
</style>
